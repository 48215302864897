import axios from "axios";
import api, { apiBaseUrl } from "./config/apiConfig";
import { ProfileSuccessResponse } from "@greatsumini/react-facebook-login";

const loginEmail = async (email: string, password: string) => {
  try {
    const encodedPassword = btoa(password); // Encode password using Base64
    const response = await api.post(`${apiBaseUrl}/login`, {
      email,
      password: encodedPassword,
    });
    if (response.status === 200 && response.data.accessToken) {
      return response.data;
    } else {
      throw new Error("Login failed");
    }
  } catch (error) {
    console.error("Login failed:", error);
    throw error;
  }
};

const loginGoogle = async (code: string) => {
  try {
    const response = await axios.post(`${apiBaseUrl}/login/google`, { code });
    if (response.status === 200 && response.data.accessToken) {
      return response.data;
    } else {
      throw new Error("Login failed");
    }
  } catch (error) {
    console.error("Login failed:", error);
    throw error;
  }
};

const validateGoogleToken = async (code: string) => {
  try {
    const response = await axios.post(`${apiBaseUrl}/login/validateCode`, { code });
    if (response.status === 200 && response.data.accessToken) {
      return response.data;
    } else {
      throw new Error("Login failed");
    }
  } catch (error) {
    console.error("Login failed:", error);
    throw error;
  }
};

const loginFacebook = async (profil: ProfileSuccessResponse) => {
  try {
    const response = await axios.post(`${apiBaseUrl}/login/facebook`, { profil });

    if (response.status === 200 && response.data.accessToken) {
      return response.data;
    } else {
      throw new Error("Login failed");
    }
  } catch (error) {
    console.error("Login failed:", error);
    throw error; // Rethrow the error to be handled by the calling component
  }
};

export { loginEmail, loginFacebook, loginGoogle, validateGoogleToken };
