import { FunctionComponent, useMemo, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../providers/AuthProvider";
import { googleLogout } from '@react-oauth/google';
import React, { useEffect, useState } from 'react';
import { Video, Guitar, UserCircle, FileVideo, Sword } from "@phosphor-icons/react";

const Header: FunctionComponent = () => {
  const { authState, logout } = useAuth();
  const navigate = useNavigate();

  /** Action props */
  const onHomeClick = useCallback(() => {
    navigate("/home");
  }, [navigate]);

  // const onHowToPlayClick = useCallback(() => {
  //   navigate("/how-to-play");
  // }, [navigate]);

  const onLearnSongsTextClick = () => {
    navigate("/learn-song-list");
  };

  const onBattlesTextClick = useCallback(() => {
    navigate("/battles");
  }, [navigate]);

  const onChallengesTextClick = useCallback(() => {
    navigate("/challenges");
  }, [navigate]);

  const onLogInTextClick = useCallback(() => {
    navigate("/sign-in");
  }, [navigate]);

  const onLogOutClick = useCallback(() => {
    googleLogout();
    logout();
  }, []);

  const onMyDashboardClick = useCallback(() => {
    navigate("/my-dashboard");
  }, []);

  const onMyVideosClick = useCallback(() => {
    navigate("/my-videos");
  }, [navigate]);

  const onMyLessonsClick = useCallback(() => {
    navigate("/my-lessons");
  }, []);

  const onMyBattlesClick = useCallback(() => {
    navigate("/my-battles");
  }, []);

  const onMyChallengesClick = useCallback(() => {
    navigate("/my-challenges");
  }, []);

  const onSignUpTextClick = useCallback(() => {
    navigate("/sign-up");
  }, [navigate]);

  const onAdminClick = useCallback(() => {
    navigate("/admin");
  }, [navigate]);

  const [scroll, setScroll] = useState(false);
  useEffect(() => {
    window.addEventListener("scroll", () => {
      setScroll(window.scrollY > 120);
    });
  }, []);

  const onProfileClick = useCallback(() => {
    navigate("/my-profile");
  }, [navigate]);

  return (
    <div className={scroll ? "scrolled" : "not-scrolled"}>
      <header>
        <div className="container-gbc">
          <div className="header d-flex flex-wrap align-items-center justify-content-between column-gap-3">
            <button
              onClick={onHomeClick}
              className="logo d-flex border-0 bg-transparent p-0"
            >
              <img src="/images/gbc-logos/gbc-logo-black.svg" alt="" />
            </button>
            <div className="d-md-block d-lg-none">
              <button
                className="navbar-toggler"
                data-bs-toggle="offcanvas"
                data-bs-target="#mobileMenu"
                aria-controls="mobileMenu"
              >
                <span className="navbar-toggler-icon"></span>
              </button>
            </div>

            <ul className="nav col-lg-auto me-lg-auto mb-2 mb-md-0 d-none d-lg-flex">
              {/* <li><a href="" onClick={onHowToPlayClick} className="nav-link">How to play</a></li> */}
              <li>
                <a href="" onClick={onLearnSongsTextClick} className="nav-link">
                  Learn songs
                </a>
              </li>
              <li>
                <a href="" onClick={onBattlesTextClick} className="nav-link">
                  Battle arena
                </a>
              </li>
            </ul>

            {authState.isAuthenticated ? (
              <div className="dropdown text-end d-none d-lg-block">
                <a
                  href=""
                  className="d-flex align-items-center column-gap-3 nav-link"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  <img
                    crossOrigin="anonymous"
                    src={authState.user?.picture || "/profile_placeholder.png"}
                    alt="mdo"
                    width="44"
                    height="44"
                    className="border border-white rounded-circle"
                  />
                  {/* <p className="mb-0">
                    {authState.user?.firstName} {authState.user?.lastName}
                  </p> */}
                </a>
                <ul className="dropdown-menu text-small">
                  {authState.user?.isAdmin ? (
                    <li>
                      <a
                        href=""
                        onClick={onAdminClick}
                        className="dropdown-item p-3"
                      >
                        Admin
                      </a>
                    </li>
                  ) : null}
                  {/* <li>
                    <a
                      href=""
                      onClick={onMyDashboardClick}
                      className="dropdown-item p-3"
                    >
                      My dashboard
                    </a>
                  </li> */}
                  <li>
                    <a
                      href=""
                      onClick={onProfileClick}
                      className="dropdown-item p-3"
                    >
                      My profile
                    </a>
                  </li>
                  <li>
                    <a
                      href=""
                      onClick={onMyVideosClick}
                      className="dropdown-item p-3"
                    >
                      My videos
                    </a>
                  </li>
                  <li>
                    <a
                      href=""
                      onClick={onMyLessonsClick}
                      className="dropdown-item p-3"
                    >
                      My lessons
                    </a>
                  </li>
                  <li>
                    <a
                      href=""
                      onClick={onMyBattlesClick}
                      className="dropdown-item p-3"
                    >
                      My battles
                    </a>
                  </li>
                  <li>
                    <a
                      href=""
                      onClick={onMyChallengesClick}
                      className="dropdown-item p-3"
                    >
                      My challenges
                    </a>
                  </li>

                  <li>
                    <hr className="dropdown-divider" />
                  </li>

                  <li>
                    <a
                      href=""
                      onClick={onLogOutClick}
                      className="dropdown-item p-3"
                    >
                      Log out
                    </a>
                  </li>
                </ul>
              </div>
            ) : (
              <div className="d-none d-lg-block">
                <ul className="nav col-lg-auto me-lg-auto mb-2 mb-md-0">
                  <li>
                    <a
                      href=""
                      onClick={onLogInTextClick}
                      className="bttn bttn-stroke me-md-4"
                    >
                      Log in
                    </a>
                  </li>
                  <li>
                    <a href="" onClick={onSignUpTextClick} className="bttn">
                      Sign up
                    </a>
                  </li>
                </ul>
              </div>
            )}
          </div>
        </div>
      </header>
      {/* Mobile menu */}
      <div
        className="offcanvas offcanvas-top h-auto m-3 border-0 rounded-5 overflow-hidden"
        id="mobileMenu"
        aria-labelledby="offcanvasLabel"
      >
        <div className="offcanvas-body text-end p-0 rounded-0">
          <div className="d-flex flex-column sticky-top gap-2 p-3 bg-yellow">
            <div className="d-flex justify-content-between">
              <button
                onClick={onHomeClick}
                className="logo d-flex border-0 bg-transparent p-0"
              >
                <img src="/images/gbc-logos/gbc-logo-black.svg" alt="" />
              </button>
              <button
                type="button"
                className="btn-close text-black"
                data-bs-dismiss="offcanvas"
                aria-label="Close"
              ></button>
            </div>

            <div className="d-flex justify-items-between py-2 gap-3 mt-2">
              <a
                href=""
                onClick={onLearnSongsTextClick}
                className="bttn bttn-smaller d-flex flex-fill justify-content-center"
              >
                Learn songs
              </a>
              <a
                href=""
                onClick={onBattlesTextClick}
                className="bttn bttn-smaller d-flex flex-fill justify-content-center"
              >
                Battle arena
              </a>
            </div>
          </div>

          {authState.isAuthenticated ? (
            <>
              <div className="d-flex flex-row-reverse align-items-center column-gap-3 py-4 px-3 bg-gray-light">
                <div
                  className="d-block text-black text-decoration-none"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                  onClick={onProfileClick}
                >
                  <img
                    crossOrigin="anonymous"
                    src={authState.user?.picture || "/profile_placeholder.png"}
                    alt="gbc"
                    width="44"
                    height="44"
                    className="rounded-circle"
                  />
                </div>
                <p className="mb-0 fw-bold fs-5" onClick={onProfileClick}>
                  {authState.user?.firstName} {authState.user?.lastName}
                </p>
              </div>
              <ul className="nav flex-column">
                {authState.user?.isAdmin ? (
                  <li>
                    <a
                      href=""
                      onClick={onAdminClick}
                      className="pt-3 nav-link text-black d-flex gap-3 align-items-center justify-content-end"
                    >
                      <span>Admin</span>
                      <Video size={32} color="#ccc" />
                    </a>
                  </li>
                ) : null}
                {/* <li>
                  <a
                    href=""
                    onClick={onMyDashboardClick}
                    className="pt-3 nav-link text-black d-flex gap-3 align-items-center justify-content-end"
                  >
                    <span>My dashboard</span>
                  </a>
                </li> */}
                <li>
                  <a
                    href=""
                    onClick={onProfileClick}
                    className="pt-3 nav-link text-black d-flex gap-3 align-items-center justify-content-end"
                  >
                    <span>My profile</span>
                    <UserCircle size={32} color="#ccc" />
                  </a>
                </li>
                <li>
                  <a
                    href=""
                    onClick={onMyVideosClick}
                    className="pt-3 nav-link text-black d-flex gap-3 align-items-center justify-content-end"
                  >
                    <span>My videos</span>
                    <Video size={32} color="#ccc" />
                  </a>
                </li>
                <li>
                  <a
                    href=""
                    onClick={onMyLessonsClick}
                    className="pt-3 nav-link text-black d-flex gap-3 align-items-center justify-content-end"
                  >
                    <span>My lessons</span>
                    <FileVideo size={32} color="#ccc" />
                  </a>
                </li>
                <li>
                  <a
                    href=""
                    onClick={onMyBattlesClick}
                    className="pt-3 nav-link text-black d-flex gap-3 align-items-center justify-content-end"
                  >
                    <span>My battles</span>
                    <Guitar size={32} color="#ccc" />
                  </a>
                </li>
                <li>
                  <a
                    href=""
                    onClick={onMyChallengesClick}
                    className="pt-3 nav-link text-black d-flex gap-3 align-items-center justify-content-end"
                  >
                    <span>My challenges</span>
                    <Sword size={32} color="#ccc" />
                  </a>
                </li>
                <li>
                  <hr className="dropdown-divider" />
                </li>
              </ul>
              <div className="mt-auto d-flex border-top-1 w-100 justify-content-end p-3 align-self-end">
                <a
                  href=""
                  onClick={onLogOutClick}
                  className="bttn bttn-smaller bttn-stroke d-inline-flex gap-3 align-items-center justify-content-end"
                >
                  Log out
                </a>
              </div>
            </>
          ) : (
            <div className="d-flex flex-column flex-wrap gap-3 p-3 text-center">
              <h3>Seems like you need to login or create an account.</h3>
              <a href="" onClick={onLogInTextClick} className="bttn me-md-4 d-flex flex-fill justify-content-center">
                Log in
              </a>
              <a
                href=""
                onClick={onSignUpTextClick}
                className="bttn bttn-stroke d-flex flex-fill justify-content-center"
              >
                Sign up
              </a>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Header;
