import { configureStore } from '@reduxjs/toolkit'

import videosReducer from './videosSlice'
import battlesSlice from './battlesSlice';
import challengesSlice from './challengesSlice';

const store = configureStore({
  reducer: {
    songs: videosReducer,
    battles: battlesSlice,
    challenges: challengesSlice,
  },
});

export default store;

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;