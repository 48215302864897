import { FC, useCallback } from 'react';
import { useNavigate } from "react-router-dom";
import DashboardElement from './components/DashboardElement';
import { useAuth } from "../providers/AuthProvider";
import React, { useState, useEffect } from "react";
import { LockSimple } from "@phosphor-icons/react";
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import Accordion from "./components/Accordion";

const MyDashboard: React.FC = () => {
  const navigate = useNavigate();

  const onClickMyVideos = useCallback(() => {
    navigate("/my-videos");
  }, [navigate]);

  const onClickMyLessons = useCallback(() => {
    navigate("/my-lessons");
  }, [navigate]);

  const onClickMyBattles = useCallback(() => {
    navigate("/my-battles");
  }, [navigate]);

  const { authState, logout } = useAuth();

  const [scroll, setScroll] = useState(false);
  useEffect(() => {
    const handleScroll = () => {
      setScroll(window.scrollY > 275);
    };

    window.addEventListener("scroll", handleScroll);

    // Cleanup event listener on component unmount
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  const onProfileClick = useCallback(() => {
    navigate("/my-profile");
  }, [navigate]);

  // Define the accordion items
  const accordionItems = [
    {
      id: 1,
      title: 'Tournament 01',
      content: (
        <>
          <div className="d-flex justify-content-between">
            <div className="d-flex flex-column w-100 flex-wrap justify-content-between">
              <p className="mb-0">Date</p>
              <p className="mb-0 fw-bold">November 8, 2024</p>
            </div>
            <div className="d-flex flex-column w-100 flex-wrap justify-content-between">
              <p className="mb-0">Entry Fee</p>
              <p className="mb-0 fw-bold">2000 XP</p>
            </div>
          </div>
          <div className="mt-3">
            <p>In publishing and graphic design, Lorem ipsum is a placeholder text commonly used to demonstrate the visual form of a document or a typeface without relying on meaningful content. Lorem ipsum may be used as a placeholder before the final copy is available.</p>
          </div>
          <button className="mt-2 bttn bttn-smaller">
            Join Tournament
          </button>
        </>
      ),
    },
    {
      id: 2,
      title: 'Tournament 02',
      content: (
        <>
          <div className="d-flex justify-content-between">
            <div className="d-flex flex-column w-100 flex-wrap justify-content-between">
              <p className="mb-0">Date</p>
              <p className="mb-0 fw-bold">November 10, 2024</p>
            </div>
            <div className="d-flex flex-column w-100 flex-wrap justify-content-between">
              <p className="mb-0">Entry Fee</p>
              <p className="mb-0 fw-bold">2000 XP</p>
            </div>
          </div>
          <div className="mt-3">
            <p>In publishing and graphic design, Lorem ipsum is a placeholder text commonly used to demonstrate the visual form of a document or a typeface without relying on meaningful content. Lorem ipsum may be used as a placeholder before the final copy is available.</p>
          </div>
          <button className="mt-2 bttn bttn-smaller">
            Join Tournament
          </button>
        </>
      ),
    },
  ];

  return (
    <>
      <div className="bg-yellow pt-5">
        <div className="container-gbc pt-5 text-black">
          <div className="row text-center pt-5 justify-content-center">
            <div className="col-12 col-md-8 col-lg-6 col-xl-4">
              <div className="d-flex gap-3 text-start">
                <div>
                  {authState.isAuthenticated ? (
                    <img
                      crossOrigin="anonymous"
                      src={authState.user?.picture || "/profile_placeholder.png"}
                      alt="mdo"
                      width="64"
                      height="64"
                      className="rounded-circle"
                    />
                  ) : (
                    <></>
                  )}
                </div>
                <div className="d-flex w-100 flex-column">
                  <h2 className="fs-4 fw-bold mb-1">
                    {authState.user?.firstName} {authState.user?.lastName}
                  </h2>
                  <div className="d-flex flex-wrap gap-1 w-100">
                    <div className="border rounded-2 p-1 d-flex flex-column gap-1 flex-fill">
                      <p className="m-0 fs-6 lh-1">Game XP</p>
                      <p className="m-0 fw-bold fs-5 lh-1">1379</p>
                    </div>
                    <div className="border rounded-2 p-1 d-flex flex-column gap-1 flex-fill">
                      <p className="m-0 fs-6 lh-1">Battles won</p>
                      <p className="m-0 fw-bold fs-5 lh-1">2/16</p>
                    </div>
                    <div className="border rounded-2 p-1 d-flex flex-column gap-1 flex-fill">
                      <p className="m-0 fs-6 lh-1">Badges</p>
                      <p className="m-0 fw-bold fs-5 lh-1">2/9</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="bg-green">

        <div
          className={
            scroll
              ? "scrolled bg-yellow border-bottom sticky-top-custom"
              : "not-scrolled bg-yellow border-bottom sticky-top-custom"
          }
        >
          <div className="d-flex flex-column  text-center gap-3 text-black px-4 align-items-center">
            <button onClick={onProfileClick} className="bttn">Complete your profile</button>
            <p className="mb-0 lh-1"><small>Complete your profile to be able to do something lorem ipsum dolorset.</small></p>
          </div>
        </div>

        <div className="container-gbc py-4 py-md-5">
          <div className="row">
            <div className="col-12 col-md-6 col-lg-4 mb-4">
              <Accordion items={accordionItems} />
            </div>
            <div className="col-12 col-md-6 col-lg-4 mb-4">
              <div className="px-3 py-3 px-md-4 py-md-4 rounded-5 bg-white text-black border gap-3 d-flex flex-column h-100">
                <div className="d-flex w-100 justify-content-between">
                  <div className="d-flex flex-wrap w-100 gap-2">
                    <h3 className="fs-4 fw-bold mb-2">Game Badges</h3>
                    <div className="d-flex flex-wrap w-100 gap-2">
                      <div className="bg-yellow rounded-2 p-2 flex-fill">
                        <LockSimple size={20} color="#000" weight="fill" />
                        <p className="mb-1 mt-3 fw-bold lh-1">GBC Member</p>
                        <p className="mb-0 lh-1"><small>Complete your profile</small></p>
                      </div>
                      <div className="bg-yellow rounded-2 p-2 flex-fill">
                        <LockSimple size={20} color="#000" weight="fill" />
                        <p className="mb-1 mt-3 fw-bold lh-1">Rising Star</p>
                        <p className="mb-0 lh-1"><small>After the first battle</small></p>
                      </div>
                      <div className="bg-yellow rounded-2 p-2 flex-fill">
                        <LockSimple size={20} color="#000" weight="fill" />
                        <p className="mb-1 mt-3 fw-bold lh-1">Bronze Guitarist</p>
                        <p className="mb-0 lh-1"><small>Accumulate 3.000 XP</small></p>
                      </div>
                      <div className="bg-yellow rounded-2 p-2 flex-fill">
                        <LockSimple size={20} color="#000" weight="fill" />
                        <p className="mb-1 mt-3 fw-bold lh-1">Silver guitarist</p>
                        <p className="mb-0 lh-1"><small>Accumulate 5.000 XP</small></p>
                      </div>
                      <div className="bg-yellow rounded-2 p-2 flex-fill">
                        <LockSimple size={20} color="#000" weight="fill" />
                        <p className="mb-1 mt-3 fw-bold lh-1">Gold Guitarist</p>
                        <p className="mb-0 lh-1"><small>Accumulate 10.000 points, eligible to receive more for each additional 10.000</small></p>
                      </div>
                      <div className="bg-yellow rounded-2 p-2 flex-fill">
                        <LockSimple size={20} color="#000" weight="fill" />
                        <p className="mb-1 mt-3 fw-bold lh-1">Battle guitar hero</p>
                        <p className="mb-0 lh-1"><small>Win 5 battles, receive more for every additional 5 battles won</small></p>
                      </div>
                      <div className="bg-yellow rounded-2 p-2 flex-fill">
                        <LockSimple size={20} color="#000" weight="fill" />
                        <p className="mb-1 mt-3 fw-bold lh-1">Tournament Winner</p>
                        <p className="mb-0 lh-1"><small>Upon winning the first tournament</small></p>
                      </div>
                      <div className="bg-yellow rounded-2 p-2 flex-fill">
                        <LockSimple size={20} color="#000" weight="fill" />
                        <p className="mb-1 mt-3 fw-bold lh-1">Tournament Star</p>
                        <p className="mb-0 lh-1"><small>For every 5 tournaments won</small></p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-12 col-md-6 col-lg-4 mb-4">
              <Tabs>
                <TabList>
                  <Tab>How to get XP</Tab>
                  <Tab>Where to use XP</Tab>
                </TabList>

                <TabPanel>
                  {/* <h3 className="fs-4 fw-bold mb-3">How to get XP</h3> */}
                  <div className="d-flex w-100 flex-wrap justify-content-between">
                    <p className="mb-0">Create account</p>
                    <p className="mb-0 fw-bold">200 XP</p>
                  </div>
                  <div className="d-flex w-100 flex-wrap justify-content-between">
                    <p className="mb-0">Subscription payment</p>
                    <p className="mb-0 fw-bold">200 XP</p>
                  </div>
                  <div className="d-flex w-100 flex-wrap justify-content-between">
                    <p className="mb-0">Complete profile</p>
                    <p className="mb-0 fw-bold">50 XP</p>
                  </div>
                  <div className="d-flex w-100 flex-wrap justify-content-between">
                    <p className="mb-0">Mark lesson as learned</p>
                    <p className="mb-0 fw-bold">15 XP</p>
                  </div>
                  <p className="fw-bold mt-2 mb-0 fs-5">Daily login</p>
                  <div className="d-flex w-100 flex-wrap justify-content-between">
                    <p className="mb-0">First login of the series</p>
                    <p className="mb-0 fw-bold">5 XP</p>
                  </div>
                  <div className="d-flex w-100 flex-wrap justify-content-between">
                    <p className="mb-0">Login on Days 2, 3, 4 of the series</p>
                    <p className="mb-0 fw-bold">10 XP/Day</p>
                  </div>
                  <div className="d-flex w-100 flex-wrap justify-content-between">
                    <p className="mb-0">Daily login after Day 5</p>
                    <p className="mb-0 fw-bold">20 XP/Day</p>
                  </div>
                  <p className="fw-bold mt-2 mb-0 fs-5">Upload video</p>
                  <div className="d-flex w-100 flex-wrap justify-content-between">
                    <p className="mb-0">Rhythm</p>
                    <p className="mb-0 fw-bold">30 XP</p>
                  </div>
                  <div className="d-flex w-100 flex-wrap justify-content-between">
                    <p className="mb-0">Intonation</p>
                    <p className="mb-0 fw-bold">25 XP</p>
                  </div>
                  <div className="d-flex w-100 flex-wrap justify-content-between">
                    <p className="mb-0">Originality</p>
                    <p className="mb-0 fw-bold">20 XP</p>
                  </div>
                  <div className="d-flex w-100 flex-wrap justify-content-between mb-2">
                    <p className="mb-0">Improvisation</p>
                    <p className="mb-0 fw-bold">20 XP</p>
                  </div>
                  <div className="d-flex w-100 flex-wrap justify-content-between">
                    <p className="mb-0">Accept challenge</p>
                    <p className="mb-0 fw-bold">50 XP</p>
                  </div>
                  <div className="d-flex w-100 flex-wrap justify-content-between">
                    <p className="mb-0">Participate in battle</p>
                    <p className="mb-0 fw-bold">100 XP</p>
                  </div>
                  <div className="d-flex w-100 flex-wrap justify-content-between">
                    <p className="mb-0">Win a battle</p>
                    <p className="mb-0 fw-bold">300 XP</p>
                  </div>
                  <div className="d-flex w-100 flex-wrap justify-content-between">
                    <p className="mb-0">Win a tournament</p>
                    <p className="mb-0 fw-bold">5000 XP</p>
                  </div>
                </TabPanel>
                <TabPanel>
                  <div className="d-flex w-100 flex-wrap justify-content-between">
                    <p className="mb-0">Challenge to battle</p>
                    <p className="mb-0 fw-bold">50 XP</p>
                  </div>
                  <div className="d-flex w-100 flex-wrap justify-content-between">
                    <p className="mb-0">Register for a tournament</p>
                    <p className="mb-0 fw-bold">2000 XP</p>
                  </div>
                  <div className="d-flex w-100 flex-wrap justify-content-between">
                    <p className="mb-0">30-minute concert</p>
                    <p className="mb-0 fw-bold">15000 XP</p>
                  </div>
                </TabPanel>
              </Tabs>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default MyDashboard;
