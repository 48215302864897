import React, { ChangeEvent } from "react";

interface EmailInputProps {
  value: string;
  onChange: (value: string) => void;
}

const EmailInput: React.FC<EmailInputProps> = ({ value, onChange }) => {
  const handleEmailChange = (e: ChangeEvent<HTMLInputElement>) => {
    onChange(e.target.value);
  };

  return (
    <div className="mb-3 form-floating">
      <input
        type="email"
        className="form-control"
        id="email"
        name="email"
        value={value}
        placeholder="Email address"
        onChange={handleEmailChange}
        required
      />
      <label htmlFor="email" className="form-label">
        Email address
      </label>
    </div>
  );
};

export default EmailInput;
