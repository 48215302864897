import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import ResetPassword from "./ResetPassword";

const ResetPasswordContainer: React.FC = () => {
  const navigate = useNavigate();
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);

  const handleResetPasswordSuccess = () => {
    setShowSuccessMessage(true);
  };

  const handleResetPasswordClose = () => {
    navigate("/home");
  };

  return (
    <div className="login-bgs">
      <div className="container-gbc py-5">
        <div className="row justify-content-center">
          <div className="col col-md-10 col-lg-6">
            <div className="rounded-5 p-5 p-md-5 my-5 card-songs">
              <div>
                {showSuccessMessage ? (
                  <div>
                    <p>You have successfully updated your password.</p>
                    {/* Optionally, you can add a button to close */}
                    <button className="bttn" onClick={handleResetPasswordClose}>
                      Close
                    </button>
                  </div>
                ) : (
                  <ResetPassword
                    onSuccess={handleResetPasswordSuccess}
                    onClose={handleResetPasswordClose}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ResetPasswordContainer;
