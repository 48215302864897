import api, { apiBaseUrl } from "./config/apiConfig";
import { OpponentVideo } from "../models/OpponentVideo";

export const getOpponentVideosBySongId = async (
  accessToken: string,
  songId: string
): Promise<OpponentVideo[]> => {
  try {
    const response = await api.get(`${apiBaseUrl}/opponents?songId=${songId}`, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });

    if (response.status === 200) {
      return response.data;
    } else {
      throw new Error("Get opponents failed");
    }
  } catch (error) {
    console.error("Get opponents failed:", error);
    throw error;
  }
};
