import { FunctionComponent, useCallback, useEffect, useState } from "react";
import AWSVideoPlayerForm from "../components/AWSVideoPlayerForm";
import SongCardsContainer from "./SongCardsContainer";
import { useLocation, useNavigate } from "react-router-dom";
import { LearnSongsCardType } from "./LearnSongsCard";
import { useAuth } from "../../providers/AuthProvider";
import SongToolsCard from "../songs/SongToolsCard";
import { Disable } from 'react-disable';
import { Helmet } from "react-helmet-async";

const LearnSongsSingleLesson: FunctionComponent = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { song } = location.state;
  // const { authState } = useAuth();
  const { authState, logout } = useAuth();
  const { tokens } = authState;
  const similarSongs: any = [];
  const [isLoggedIn, setIsLoggedIn] = useState<boolean>(!!tokens?.access_token);

  const onLogInTextClick = useCallback(() => {
    navigate("/sign-in");
  }, [navigate]);

  const onSignUpTextClick = useCallback(() => {
    navigate("/sign-up");
  }, [navigate]);

  useEffect(() => {
    setIsLoggedIn(!!tokens?.access_token);
  }, [tokens]);

  const onClickSong = useCallback(
    (song: LearnSongsCardType) => {
      navigate("/learn-song-play", { state: { id: song.id } });
    },
    [navigate]
  );

  const onBackClick = () => {
     navigate(-1);
  };

  const onUploadVideoClick = () => {
    navigate("/upload-video", { state: { song: song } });
  };

  return (
    <>
      {/* Helmet component to dynamically set the page title */}
      <Helmet>
        <title>
          {song?.songTitle
            ? `Learn Song - ${song.songTitle} by ${song.artistName}`
            : "Learn Songs"}
        </title>
      </Helmet>

      <div className="hero-section text-white d-flex align-items-center hero-single">
        <div className="container-gbc w-100">
          <div className="row align-items-top">
            <div className="col-12 col-md-12 col-lg-5 order-2">
              {/* <Disable disabled={!isLoggedIn}> */}
              {authState.isAuthenticated ? (
                <div className="d-flex flex-column justify-content-between h-100">
                  <div className="text-center px-3 py-3 px-md-4 py-md-5 rounded-5 bg-white text-black border gap-3 d-flex flex-column h-100 justify-content-center">
                    <h4 className="m-0 fs-2 fw-bold">Want to battle others?</h4>
                    <p className="m-0">
                      Record and upload a video, and after feedback you can find
                      opponents to battle.
                    </p>
                    <div className="d-flex justify-content-center mx-auto my-0">
                      <button
                        onClick={onUploadVideoClick}
                        className="bttn align-self-start"
                      >
                        {song?.hasUserVideo ? "Upload your video" : "Upload your video"}
                      </button>
                    </div>
                  </div>
                  <SongToolsCard song={song} />
                </div>
              ) : (
                <div className="d-flex flex-column justify-content-between h-100">
                  <div className="text-center px-3 py-3 px-md-4 py-md-5 rounded-5 bg-white text-black border gap-3 d-flex flex-column h-100 justify-content-between">
                    <div className="gap-3 gap-md-4 d-flex flex-column">
                      <p className="mb-0">Do you want to learn this song?</p>
                      <h4 className="m-0 fs-2 fw-bold px-2 px-md-5">
                        Register now and get 3 months unlimited access for free.
                      </h4>
                      <button
                        onClick={onSignUpTextClick}
                        className="bttn mx-auto"
                      >
                        Create an account
                      </button>
                    </div>
                    <p className="mb-0">
                      Already have an account?{" "}
                      <a
                        href=""
                        onClick={onLogInTextClick}
                        className="text-black text-decoration-none fw-bold"
                      >
                        Log in now
                      </a>
                    </p>
                  </div>
                </div>
              )}

              {/* </Disable> */}
            </div>
            <div className="col-12 col-md-12 col-lg-7 mb-3 mb-lg-0 order-1">
              <div className="bg-yellow d-flex flex-column video-container-outer border rounded-5 h-100">
                <AWSVideoPlayerForm
                  videoUrl={song.videoUrl}
                  thumbnailUrl={song.thumbnailUrl}
                  isPrivate={true}
                />
                <div className="text-black p-4 p-md-5 border-top">
                  <div className="d-flex justify-content-between align-items-start flex-wrap gap-1">
                    <div>
                      <h3 className="fs-4 m-0 fw-bold">{song?.artistName}</h3>
                      <p className="mb-0 fs-6">{song?.songTitle}</p>
                    </div>
                    <div className="text-capitalize bg-white rounded-pill px-3 py-2 d-inline-flex bg-opacity-50">
                      <small>
                        {song?.level} / {song?.subLevel}{" "}
                      </small>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="bg-yellow text-black border-top">
        <div className="container-gbc py-5">
          <div className="row text-center py-5">
            <div className="col-12">
              <div className="d-flex flex-column justify-content-center align-items-center gap-2">
                <h2 className="fw-normal fs-gbc-1 mb-2">
                  More songs available
                </h2>
                <p className="fs-gbc-2 fw-lighter mb-5 col-md-8">
                  Please select a song you would like to learn.
                </p>
                <button onClick={onBackClick} className="bttn">
                  Back to song list
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="container-card bg-yellow">
        <div className="container-gbc">
          <SongCardsContainer
            childAction={() => onClickSong(song)}
            songs={similarSongs}
            pageCount={0}
            showPagination={false}
            currentPage={0}
          />
        </div>
      </div>
    </>
  );
};

export default LearnSongsSingleLesson;
