import React, { useState } from "react";
import ForgotPasswordRequestForm from "./ForgotPasswordRequestForm";
import { forgotPassword } from "../../api/forgotPassword";

interface ForgotPasswordContainerProps {
  onClose: () => void;
}

const ForgotPasswordContainer: React.FC<ForgotPasswordContainerProps> = (
  props
) => {
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string | null>(null); // State to manage error messages

  const onForgotPassword = async (email: string) => {
    try {
      await forgotPassword(email);
      setShowSuccessMessage(true);
      setErrorMessage(null); // Clear any previous error on success
    } catch (error) {
      console.error("Forgot password failed:", error);
      setErrorMessage(
        "Failed to send the reset email. Please try again later."
      ); // Set the error message
    }
  };

  const handleForgotPasswordClose = () => {
    props.onClose(); // Call the onClose function passed from props
  };

  const handleRetry = () => {
    setErrorMessage(null); // Clear the error message when retrying
  };

  return (
    <div>
      {showSuccessMessage ? (
        <div>
          <h3 className="fw-bold fs-1">Password reset sent</h3>
          <p>
            If you are registered with the provided email address, we have sent
            you an email to reset your password.
          </p>
          <button className="bttn" onClick={handleForgotPasswordClose}>
            Close
          </button>
        </div>
      ) : errorMessage ? (
        <>
          <div className="mt-5 text-center">
            <p>{errorMessage}</p>
            <button className="bttn w-100" onClick={handleRetry}>
              Try Again
            </button>
          </div>
          <div className="gap-3 mt-3 align-items-center justify-content-between">
            <button className="bttn w-100" onClick={handleForgotPasswordClose}>
              Close
            </button>
          </div>
        </>
      ) : (
        <ForgotPasswordRequestForm
          onForgotPassword={onForgotPassword}
          onClose={handleForgotPasswordClose}
        />
      )}
    </div>
  );
};

export default ForgotPasswordContainer;
