import api, { apiBaseUrl } from "./config/apiConfig";
import { Song } from "../models/Song";
import axios from "axios";

export const getMyLessons = async (accessToken: string): Promise<Song[]> => {
  try {
    const response = await api.get(`${apiBaseUrl}/lessons`, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });

    if (response.status === 200) {
      return response.data;
    } else {
      throw new Error("Get lessons failed");
    }
  } catch (error) {
    console.error("Get lessons failed:", error);
    throw error;
  }
};

export const addLesson = async (
  accessToken: string,
  songId: string
): Promise<Song[]> => {
  try {
    const response = await axios.post(
      `${apiBaseUrl}/lessons`,
      { songId: songId },
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );

    if (response.status === 200) {
      return response.data;
    } else {
      throw new Error("Add lesson failed");
    }
  } catch (error: any) {
    if (error.response?.status === 401) {
      return [];
    }
    throw error;
  }
};
