import React from 'react';
import {
  FacebookShareButton,
  TwitterShareButton,
  FacebookIcon,
  TwitterIcon
} from 'react-share';

interface SocialShareProps {
  url: string;
  title: string;
}

const SocialShare: React.FC<SocialShareProps> = ({ url, title }) => {
  console.log(url, title);
  return (
    <div className="social-share d-flex gap-2">
      <FacebookShareButton url={url} hashtag="#GuitarBattleClub">
        <FacebookIcon size={44} round />
      </FacebookShareButton>
      <TwitterShareButton url={url} title={title}>
        <TwitterIcon size={44} round />
      </TwitterShareButton>
    </div>
  );
};

export default SocialShare;
