import { createSlice } from '@reduxjs/toolkit'

const initialState : any[]=
    [{
        id: 1,
        artist: "Iron Maiden",
        song: "Fear of the dark",
        player1avatar : '/avatar1.png',
        player1name: 'John Doe 1',
        player1level: '1',
        player2avatar: '/avatar2.png',
        player2name: 'John Doe 2',
        player2level: '1',
        date: 'September 15'
    },
    {
        id: 2,
        artist: "Iron Maiden",
        song: "Fear of the dark",
        player1avatar : '/avatar1.png',
        player1name: 'John Doe 1',
        player1level: '1',
        player2avatar: '/avatar2.png',
        player2name: 'John Doe 2',
        player2level: '1',
        date: 'September 15'
    },
    {
        id: 3,
        artist: "Iron Maiden",
        song: "Fear of the dark",
        player1avatar : '/avatar1.png',
        player1name: 'John Doe 1',
        player1level: '1',
        player2avatar: '/avatar2.png',
        player2name: 'John Doe 2',
        player2level: '1',
        date: 'September 15'
    },
    // {
    //     id: 4,
    //     artist: "Iron Maiden",
    //     song: "Fear of the dark",
    //     player1avatar : '/avatar1.png',
    //     player1name: 'John Doe 1',
    //     player1level: '1',
    //     player2avatar: '/avatar2.png',
    //     player2name: 'John Doe 2',
    //     player2level: '1',
    //     date: 'September 15'
    // },
    // {
    //     id: 5,
    //     artist: "Iron Maiden",
    //     song: "Fear of the dark",
    //     player1avatar : '/avatar1.png',
    //     player1name: 'John Doe 1',
    //     player1level: '1',
    //     player2avatar: '/avatar2.png',
    //     player2name: 'John Doe 2',
    //     player2level: '1',
    //     date: 'September 15'
    // },
    // {
    //     id: 6,
    //     artist: "Iron Maiden",
    //     song: "Fear of the dark",
    //     player1avatar : '/avatar1.png',
    //     player1name: 'John Doe 1',
    //     player1level: '1',
    //     player2avatar: '/avatar2.png',
    //     player2name: 'John Doe 2',
    //     player2level: '1',
    //     date: 'September 15'
    // }
    ]

const battlesSlice = createSlice({
    name: 'battles',
    initialState,
    reducers: {}
})

export default battlesSlice.reducer