import React, { ChangeEvent } from "react";

interface PasswordInputProps {
  name: string;
  value: string;
  onChange: (value: string) => void;
}

const PasswordInput: React.FC<PasswordInputProps> = ({
  name,
  value,
  onChange,
}) => {
  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    onChange(e.target.value);
  };

  return (
    <div className="mb-3 mb-md-4 form-floating">
      <input
        type="password"
        className="form-control"
        id={name.toLowerCase().replace(" ", "-")}
        name={name.toLowerCase().replace(" ", "-")}
        value={value}
        placeholder="********"
        onChange={handleChange}
        required
      />
      <label
        htmlFor={name.toLowerCase().replace(" ", "-")}
        className="form-label"
      >
        {name}
      </label>
    </div>
  );
};

export default PasswordInput;
