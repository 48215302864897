// import { FC } from "react";
// import Header from "./Header";
// import Footer from "./Footer";

// interface LayoutProps {
//     children?: React.ReactNode;
// }

// const Layout: FC<LayoutProps> = ({ children }) => {
//     return (
//         <div className="page-container">
//             <Header />
//             <div className="main-container">
//                 {children}
//             </div>
//             <Footer />
//         </div>
//     )
// }

// export default Layout;


import React, { FC } from 'react';
import { useLocation } from 'react-router-dom';
import Header from './Header';
import Footer from './Footer';

interface LayoutProps {
    children: React.ReactNode;
}

const Layout: FC<LayoutProps> = ({ children }) => {
    const location = useLocation();
    const isHomePage = location.pathname === '/' || location.pathname === '/home';

    return (
        <div className="page-container">
            {!isHomePage && <Header />}
            <div className="main-container">
                {children}
            </div>
            {!isHomePage && <Footer />}
        </div>
    );
};

export default Layout;
