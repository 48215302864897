import { FunctionComponent, useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useAuth } from "../../providers/AuthProvider";
import OpponentCard from "./OpponentCard";
import { getOpponentVideosBySongId } from "../../api/opponents";
import { OpponentVideo } from "../../models/OpponentVideo";
import { challengeToBattle } from "../../api/challenges";
import AWSVideoPlayerForm from "../components/AWSVideoPlayerForm";
import ChallengeSuccessModal from "../my_challenges/ChallengeSuccessModal";
import { Helmet } from "react-helmet-async";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMagnifyingGlass } from "@fortawesome/free-solid-svg-icons";

const FindOpponent: FunctionComponent = () => {
  const location = useLocation();
  const { userVideo } = location.state;
  const { authState } = useAuth();
  const { tokens } = authState;
  const [opponentVideos, setOpponentVideos] = useState<OpponentVideo[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [showChallengeSuccessModal, setShowChallengeSuccessModal] =
    useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [filteredOpponents, setFilteredOpponents] = useState(opponentVideos);
  const [opponentName, setOpponentName] = useState("");

  const fetchOpponents = () => {
    const controller = new AbortController(); // Define controller here
    const signal = controller.signal;

    try {
      const accessToken = tokens?.access_token || "";
      getOpponentVideosBySongId(accessToken, userVideo.song.id)
        .then((opponentVideos: OpponentVideo[]) => {
          setOpponentVideos(opponentVideos);
          setFilteredOpponents(opponentVideos);
          setLoading(false);
        })
        .catch((error) => {
          console.error("Fetch opponents failed:", error);
          setLoading(false);
        });
    } catch (error) {
      console.error("Fetch opponents failed:", error);
      setLoading(false);
    }

    // Return cleanup function
    return () => {
      controller.abort(); // Clean up the controller
    };
  };

  useEffect(() => {
    const cleanup = fetchOpponents(); // Call fetchOpponents to get the cleanup function
    return cleanup; // Return the cleanup function
  }, [userVideo.songId, tokens?.access_token]); // Make sure to include dependencies here

  // Function to handle input change
  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchQuery(event.target.value);
    filterOpponents(event.target.value);
  };

  const filterOpponents = (query: string) => {
    const filtered = opponentVideos.filter(
      (opponentVideo: OpponentVideo) =>
        opponentVideo.user.firstName
          .toLowerCase()
          .includes(query.toLowerCase()) ||
        opponentVideo.user.lastName.toLowerCase().includes(query.toLowerCase())
    );
    setFilteredOpponents(filtered);
  };

  const handleChallengeToBattleClick = async (
    opponentVideoId: number,
    opponentName: string
  ) => {
    try {
      const accessToken = tokens?.access_token || "";
      await challengeToBattle(
        accessToken,
        userVideo.id,
        opponentVideoId
      );

      // show success modal
      setOpponentName(opponentName);
      setShowChallengeSuccessModal(true);

      // refresh opponents list
      fetchOpponents();
    } catch (error) {
      console.error("Email sign up failed:", error);
    }
  };

  const handleCloseModal = () => {
    setShowChallengeSuccessModal(false);
  };

  return (
    <>
      {/* Helmet component to dynamically set the page title */}
      <Helmet>
        <title>{"Find opponent"}</title>
      </Helmet>

      <div className="hero-section text-white d-flex align-items-center hero-single">
        <div className="container-gbc w-100">
          <div className="row align-items-center justify-content-center">
            <div className="col-12 col-md-12 col-lg-7 mb-3 mb-lg-0 order-1">
              <div className="bg-yellow d-flex flex-column video-container-outer border">
                <AWSVideoPlayerForm
                  videoUrl={userVideo.videoUrl}
                  thumbnailUrl={userVideo.thumbnailUrl}
                  isPrivate={true}
                />
                <div className="text-black p-3 p-md-4 border-top">
                  <div className="d-flex justify-content-between align-items-start">
                    <div>
                      <h3 className="fs-4 m-0 fw-bold">
                        {userVideo.song?.artistName || " "}
                      </h3>
                      <p className="mb-0 fs-6">
                        {userVideo.song?.songTitle || " "}
                      </p>
                    </div>
                    <div className="text-capitalize bg-white rounded-pill px-3 py-2 d-inline-flex bg-opacity-50">
                      <small>
                        {userVideo.song?.songLevel || ""} /{" "}
                        {userVideo.song?.subLevel}
                      </small>
                    </div>
                  </div>
                  <div className="d-flex mt-4 gap-4 align-items-center bg-white rounded-4 p-4 border">
                    <img
                      width="44"
                      height="44"
                      className="rounded-circle"
                      alt={authState.user?.firstName}
                      crossOrigin="anonymous"
                      src={
                        authState.user?.picture || "/profile_placeholder.png"
                      }
                    />
                    <div className="d-flex justify-content-between w-100 align-items-center">
                      <h4 className="fw-normal m-0">
                        {authState.user?.firstName} {authState.user?.lastName}
                      </h4>
                      <div className="text-capitalize border text-black rounded-pill px-3 py-2 d-inline-flex">
                        <small>{userVideo.percentage}%</small>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="bg-green text-white">
        <div className="container-gbc pt-5">
          <div className="row text-center pt-5">
            <div className="col-12">
              <div className="d-flex flex-column justify-content-center align-items-center gap-2">
                <h2 className="fw-normal fs-gbc-1 mb-2">
                  Challenge players to battle
                </h2>
                <br />
                <p className="fs-gbc-2 fw-lighter mb-0 col-md-10 col-lg-6">
                  Available players with the same song recorded.
                </p>
                <div className="input-group input-group-lg mw-320 custom-search mt-4">
                  <input
                    type="search"
                    className="form-control"
                    placeholder="Search by username"
                    aria-label="Search by player name"
                    aria-describedby="basic-addon1"
                    value={searchQuery}
                    onChange={handleInputChange}
                  />
                  <span className="input-group-text" id="basic-addon1">
                    <FontAwesomeIcon
                      icon={faMagnifyingGlass}
                      className="me-2" // Optional: apply additional styling if needed
                    />
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="container-card bg-green">
        <div className="container-gbc py-5">
          <ChallengeSuccessModal
            show={showChallengeSuccessModal}
            onHide={handleCloseModal}
            opponentName={opponentName}
          />
          {loading && (
            <div className="text-center">
              <div className="spinner-border" role="status">
                <span className="visually-hidden">Loading...</span>
              </div>
            </div>
          )}
          {filteredOpponents.length > 0 ? (
            <div className="row">
              {filteredOpponents.map((opponentVideo) => (
                <OpponentCard
                  key={opponentVideo.id}
                  opponentVideo={opponentVideo}
                  onChallengeToBattleClick={handleChallengeToBattleClick}
                />
              ))}
            </div>
          ) : (
            <p className="text-center">
              We don't have opponents for this song at this moment. Try again
              later.
            </p>
          )}
        </div>
      </div>
    </>
  );
};;;

export default FindOpponent;
