export const formatDate = (date: Date) => {
  // Convert the UTC timestamp to local timezone
  const localDate = new Date(date);

  // Get the day, month, and year components of the date
  const day = localDate.getDate();
  const monthIndex = localDate.getMonth();
  const year = localDate.getFullYear();

  // Define an array of month names
  const monthNames = [
    "JAN",
    "FEB",
    "MAR",
    "APR",
    "MAY",
    "JUN",
    "JUL",
    "AUG",
    "SEP",
    "OCT",
    "NOV",
    "DEC",
  ];

  // Get the month name based on the month index
  const monthName = monthNames[monthIndex];

  // Format the date string as 'DD MON YYYY'
  return `${day} ${monthName} ${year}`;
};
