import React, { FunctionComponent } from "react";
import { UserVideoForFeedback } from "../../models/UserVideoForFeedback";
import { formatDate } from "../../utility/DateFormatter";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendar, faUpload } from "@fortawesome/free-solid-svg-icons";

interface AdminUserVideoCardProps {
  video: UserVideoForFeedback; // Accept the whole UserVideoForFeedback object
  action: () => void; // Define the type for the action prop
}

const AdminUserVideoCard: FunctionComponent<AdminUserVideoCardProps> = ({
  video,
  action,
}) => {
  const localCreatedDate = formatDate(new Date(video.createdAt));

  return (
    <div className="col-12 col-md-6 col-lg-4 mb-4">
      <div
        className="card-songs card-songs-listing d-flex flex-column h-100"
        onClick={action}
      >
        <div className="song-image">
          <div className="p-3 p-md-4">
            <div className="fs-6 text-white">
              <FontAwesomeIcon icon={faCalendar} className="me-2" />
              {localCreatedDate.toLocaleString()}
            </div>
            <div className="fs-6 text-white">
              <FontAwesomeIcon icon={faUpload} className="me-2" /> Upload state:{" "}
              {video.uploadState || " "}
            </div>
          </div>
          <img crossOrigin="anonymous" src={video?.thumbnailUrl} />
        </div>
        <div className="song-info px-3 d-flex  gap-3 px-md-4 py-3">
          <img
            width="44"
            height="44"
            className="rounded-circle"
            crossOrigin="anonymous"
            alt=""
            src={video.userPicture || "/profile_placeholder.png"}
          />
          <div className="d-flex flex-column w-100">
            <div className="d-flex justify-content-between">
              <h4 className="fs-4 fw-bold m-0">
                {video.userFirstName} {video.userLastName}
              </h4>
            </div>
            <div className="d-flex gap-2 align-items-center flex-wrap">
              <p className="m-0 fw-bold">{video?.song?.artistName || ""}</p>
              <p className="m-0">-</p>
              <p className="m-0 fw-normal">{video?.song?.songTitle || ""}</p>
            </div>
          </div>
        </div>
        <div className="p-3 p-md-4 d-flex">
          <div className="bttn bttn-stroke">Analyze video</div>
        </div>
      </div>
    </div>
  );
};

export default AdminUserVideoCard;
