import React from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import { Video } from "../../models/Video";
import { deleteUserVideo } from "../../api/videos";

interface DeleteVideoConfirmationPopupProps {
  onHide: () => void;
  video: Video;
  token: string;
  onSucess: () => void;
}

const DeleteVideoConfirmationPopup: React.FC<DeleteVideoConfirmationPopupProps> = ({
  onSucess,
  onHide,
  video,
  token,
}) => {
  const handleDeleteVideo = async () => {
    if (!video) {
      return;
    }
    try {
      await deleteUserVideo(token, `${video.id}`);
      onSucess();
    } catch (error) {
      console.error("Failed to delete user video:", error);
    }
    onHide();
  }

  return (
    <Modal show={true} onHide={onHide}>
      <Modal.Header>
        <Modal.Title className="fs-2 fw-bold">Success!</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p className="mb-0 fs-5">
          Are you sure you want to delete this video?
        </p>
      </Modal.Body>
      <Modal.Footer>
        <Button className="bttn bttn-stroke-yellow" variant="secondary" onClick={onHide}>
          Cancel
        </Button>
        <Button className="bttn" variant="primary" onClick={handleDeleteVideo}>
          Yes, I'm sure!
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default DeleteVideoConfirmationPopup;
