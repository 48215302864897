import { User } from "../models/User";

export const getUserName = (user: User): string => {
  if (user) {
    const { firstName, lastName } = user;
    return `${firstName} ${lastName}`;
  } else {
    return "-";
  }
};
