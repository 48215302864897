import api from "./config/apiConfig";

export const signUpWithEmail = async (
  firstName: string,
  lastName: string,
  email: string,
  password: string
) => {
  try {
    const encodedPassword = btoa(password); // Encode password using Base64
    const response = await api.post(`/signup`, {
      firstName,
      lastName,
      email,
      password: encodedPassword,
    });
    if (response.status === 200 && response.data.accessToken) {
      return response.data;
    } else {
      throw new Error("Sign up failed");
    }
  } catch (error) {
    console.error("Sign up failed:", error);
    throw error;
  }
};
