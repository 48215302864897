import React from "react";
import { Challenge } from "../../models/Challenge";
import PlayerSongCard from "../battles/components/PlayerSongCard";

interface ChallengeCardProps {
  challenge: Challenge;
  type: "SENT" | "RECEIVED";
  onCancelChallengeClick: (battleId: number) => void;
  onAcceptChallengeClick: (battleId: number) => void;
}

const ChallengeCard: React.FC<ChallengeCardProps> = ({
  challenge,
  type,
  onCancelChallengeClick,
  onAcceptChallengeClick,
}) => {
  if (!challenge) return null;

  return (
    <>
      <div className="col-12 col-md-6 col-lg-4 mb-4">
        <div className="card-songs rounded-5 p-3 bg-opacity-25 h-100 d-flex flex-column justify-content-between">
          {/* Player cards */}
          <div className="d-flex flex-column">
            <div className="d-flex gap-4 bg-white rounded-top-4 p-3 border">
              <PlayerSongCard
                user={challenge.userData?.user}
                likes={-1}
                playerStatus={undefined}
                video={challenge.userData?.video}
                tabType={"CHALLENGE"}
              />
            </div>

            <div className="d-flex gap-4 bg-white rounded-bottom-4 p-3 border-end border-bottom border-start">
              <PlayerSongCard
                user={challenge.opponentData?.user}
                likes={-1}
                playerStatus={undefined}
                video={challenge.opponentData?.video}
                tabType={"CHALLENGE"}
              />
            </div>
          </div>
          <div className="d-flex gap-3 mt-3">
            {type === "SENT" && (
              <button
                onClick={() => onCancelChallengeClick(challenge.id)}
                className="bttn"
              >
                Cancel challenge
              </button>
            )}
            {type === "RECEIVED" && (
              <button
                onClick={() => onAcceptChallengeClick(challenge.id)}
                className="bttn"
              >
                Accept challenge
              </button>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default ChallengeCard;
