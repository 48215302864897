import { FunctionComponent, useCallback, useState } from "react";
import SignUpStep1 from "./SignUpStep1";
import SignUpStep2 from "./SignUpStep2";
import { Helmet } from "react-helmet-async";

const SignUp: FunctionComponent = () => {
  const [step, setSteps] = useState(1);

  const goToNextStep = () => {
    console.log("go to next step");
    setSteps(step + 1);
  };

  return (
    <>
      {/* Helmet component to dynamically set the page title */}
      <Helmet>
        <title>{"Sing up"}</title>
      </Helmet>

      <div className="login-bgs">
        <div className="container-gbc pt-3 pb-4 py-md-5">
          <div className="row justify-content-center align-items-center">
            {step == 1 && <SignUpStep1 continue={goToNextStep} />}
            {step > 1 && <SignUpStep2 />}
          </div>
        </div>
      </div>
    </>
  );
};

export default SignUp;
