import React from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";

interface ChallengeSuccessModalProps {
  opponentName: string;
  show: boolean;
  onHide: () => void;
}

const ChallengeSuccessModal: React.FC<ChallengeSuccessModalProps> = ({
  opponentName,
  show,
  onHide,
}) => {
  return (
    <Modal show={show} onHide={onHide} centered>
      <Modal.Header closeButton>
        <Modal.Title>You've successfully challenged <br /><span className="fw-bold">{opponentName}</span> to battle!</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        Waiting for your opponent to accept. In the meantime, challenge others to keep the action going!
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={onHide}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ChallengeSuccessModal;
