import { formatDate } from "../../../utility/DateFormatter";
import { Battle } from "../../../models/Battle";
import Cookies from "js-cookie";

export const generateSocialShareTitle = (
  battle: Battle | null | undefined
): string => {
  if (!battle) {
    return "Epic Guitar Battle | Guitar Battle Club";
  }

  if (battle.endedAt != null) {
    return `Epic Guitar Battle – Ended at ${formatDate(
      new Date(battle.endedAt)
    )} | Guitar Battle Club`;
  }

  return `Epic Guitar Battle – Vote by ${formatDate(
    new Date(battle.endedAt)
  )} | Guitar Battle Club`;
};

  export function handleLikeBattle(battleId: number, playerOrder: number) {
    // Set a cookie to store that the user liked this post
    Cookies.set(`liked_gbc_${battleId}`, "true", { expires: 365 });

    // Set cookies to store that the user liked each player in the battle
    Cookies.set(`liked_gbc_${battleId}_player${playerOrder}`, "true", {
      expires: 365,
    });
  }

  export function checkIsBattleLikedByMe(battleId: number): boolean {
    // Check if the cookie exists for this battle
    return Cookies.get(`liked_gbc_${battleId}`) === "true";
  }

  export function checkIsPlayerLikedByMe(
    battleId: number,
    playerOrder: number
  ): boolean {
    // Check if the cookie exists for this battle and player
    return Cookies.get(`liked_gbc_${battleId}_player${playerOrder}`) === "true";
  }