import React, { useState, ChangeEvent, FormEvent } from "react";
import EmailInput from "../../views/sign_in/EmailInput";
import { Helmet } from "react-helmet-async";

interface ForgotPasswordProps {
  onForgotPassword: (email: string) => void;
  onClose: () => void;
}

const ForgotPassword: React.FC<ForgotPasswordProps> = (props) => {
  const [email, setEmail] = useState<string>("");

  const handleEmailChange = (e: ChangeEvent<HTMLInputElement>) => {
    setEmail(e.target.value);
  };

  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    props.onForgotPassword(email);
  };

  const handleClose = () => {
    props.onClose();
  };

  return (
    <>
      {/* Helmet component to dynamically set the page title */}
      <Helmet>
        <title>{"Forgot password"}</title>
      </Helmet>

      <div className="sign-up">
        <h3 className="fw-bold fs-1">Forgot your password</h3>
        <p>
          Enter your email address and we will send you a link to reset your
          password.
        </p>

        <form onSubmit={handleSubmit}>
          <div className="row mt-5">
            <div className="col-12 col-md-12">
              <EmailInput value={email} onChange={setEmail} />
            </div>
            <div className="col-12">
              <button type="submit" className="bttn w-100">
                Reset Password
              </button>
            </div>
            <div className="mt-5 text-center">
              <button
                type="button"
                className="bg-transparent border-0 rounded-0 fw-normal text-black text-decoration-underline"
                onClick={handleClose}
              >
                Back
              </button>
            </div>
          </div>
        </form>
      </div>
    </>
  );
};

export default ForgotPassword;
