import React from 'react';

interface AccordionItem {
    id: number;
    title: string;
    content: JSX.Element;  // Change content type from string to JSX.Element
}

interface AccordionProps {
    items: AccordionItem[];
}

const Accordion: React.FC<AccordionProps> = ({ items }) => {
    return (
        <div className="accordion col-12 col-md-8 col-lg-6 col-xl-4" id="accordionTools">
            {items.map((item, index) => (
                <div className="accordion-item" key={item.id}>
                    <div
                        id={`collapse${item.id}`}
                        className={`accordion-collapse collapse ${index === 0 ? 'collapsed' : ''}`}
                        aria-labelledby={`heading${item.id}`}
                        data-bs-parent="#accordionTools"
                    >
                        <div className="accordion-body text-black bg-white rounded-5 my-3 border p-0 overflow-hidden">
                            {item.content}  {/* This will render the JSX content */}
                        </div>
                    </div>
                    <h2 className="accordion-header" id={`heading${item.id}`}>
                        <button
                            className={`accordion-button bttn rounded-5 border shadow-none bg-black text-white collapsed ${index !== 0 ? 'collapsed' : ''}`}
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target={`#collapse${item.id}`}
                            aria-expanded={index === 0 ? 'true' : 'false'}
                            aria-controls={`collapse${item.id}`}
                        >
                            {item.title}
                        </button>
                    </h2>

                </div>
            ))}
        </div>
    );
};

export default Accordion;
