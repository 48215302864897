import { FunctionComponent } from "react";
import { Helmet } from "react-helmet-async";

const PrivacyPolicy: FunctionComponent = () => {

  return (
    <>
      {/* Helmet component to dynamically set the page title */}
      <Helmet>
        <title>{"Privacy policy"}</title>
      </Helmet>

      <div className="bg-yellow py-5 border-bottom">
        <div className="container-gbc pt-5 text-black">
          <div className="row text-center pt-5">
            <div className="col-12">
              <div className="d-flex flex-column justify-content-center align-items-center gap-2">
                <h2 className="fw-normal fs-gbc-1 mb-2">Privacy Policy</h2>
                <p className="fs-gbc-2 fw-lighter mb-0 col-md-10 col-lg-6">
                  Last update: 12.05.2024
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="bg-green">
        <div className="container-gbc py-4 py-md-5">
          <div className="row justify-content-center">
            <div className="col-12 col-md-8">
              <div className="card-songs p-3 p-md-5 bg-white">
                <p>
                  This Policy informs you about how we collect, use, transfer
                  and protect your personal data. We process them in accordance
                  with the provisions of Regulation (EU) 2016/679 of the
                  European Parliament and of the Council of April 27, 2016 on
                  the protection of natural persons with regard to the
                  processing of personal data and on the free movement of such
                  data, and repealing Directive no. 95/46/CE ("General Data
                  Protection Regulation", hereinafter "GDPR") and relevant
                  national legislation in the field of personal data protection.
                </p>
                <h3 className="fw-bold mt-4">1. Who are we?</h3>
                <p>
                  Guitar Battle Club (hereinafter may be referred as ``the
                  Company, the Controller or ``We'').
                </p>
                <p>
                  <strong>Our contact details are:</strong>
                  <br />
                  Headquarters address: 22 Plaiului Street, Nazna, Mures County,
                  Romania
                  <br />
                  Email address: info(at)guitarbattleclub.com
                </p>
                <h3 className="fw-bold mt-4">
                  2. How we process your data. Personal?
                </h3>
                <p>
                  Guitar Battle Club, in its capacity as Controller, processes
                  the personal data of users of the site
                  www.guitarbattleclub.com.
                </p>
                <p>
                  <strong>The personal data are:</strong>
                </p>
                <ul>
                  <li>
                    processed legally, fairly and transparently towards the data
                    subject;
                  </li>
                  <li>
                    collected for specific, explicit and legitimate purposes and
                    are not subsequently processed in a way incompatible with
                    these purposes;
                  </li>
                  <li>
                    adequate, relevant and limited to what is necessary in
                    relation to the purposes for which they are processed;
                  </li>
                  <li>accurate and updated on time;</li>
                  <li>
                    kept in a form that allows the identification of the data
                    subjects for a period that does not exceed the period
                    necessary to fulfill the purposes for which the data are
                    processed;
                  </li>
                  <li>
                    processed in a way that ensures adequate security of
                    personal data, including protection against unauthorized or
                    illegal processing and against accidental loss, destruction
                    or damage, by taking appropriate technical or organizational
                    measures.
                  </li>
                </ul>
                <h3 className="fw-bold mt-4">
                  3. What types of personal data are processed and for what
                  purpose are they processed?
                </h3>
                <p>
                  In order to offer free learning services using the guitar as a
                  musical instrument, the Controller will process data as
                  follows:
                </p>
                <ol>
                  <li>
                    In order to create a user account, data will be processed
                    regarding: name, surname, geographic location, age, email
                    address.
                  </li>
                  <li>
                    In order to provide the lessons, data will be processed
                    regarding: video images regarding the user's face and voice
                    as well as data regarding the user's location to the extent
                    that such details are captured on the uploaded footage.
                  </li>
                  <li>
                    In order to participate in competitions (battles), data will
                    be processed regarding: video images regarding the user's
                    face and voice, as well as data regarding the location where
                    the user is, to the extent that such details are captured on
                    the uploaded footage.
                  </li>
                </ol>
                <p>
                  For website visitors, it is possible to collect data through
                  cookies or other similar technologies, such as: IP address,
                  internet browser, location, web pages accessed on our website,
                  time spent on the website, internet network, device used. For
                  more details in this regard, see the Cookies Policy.
                  https://guitarbattleclub.com/cookies.{" "}
                </p>
                <p>
                  We do not use your personal data to send you marketing
                  communications, such as newsletters, unless you expressly
                  consent to such communications by ticking a consent box. In
                  this regard, we only process your e-mail and ensure that you
                  have a simple option to unsubscribe at any time, or to
                  withdraw your consent to receive these types of
                  communications.
                </p>
                <h3 className="fw-bold mt-4">
                  4. What are the legal grounds on which personal data process
                  is based?
                </h3>
                <p>Your personal data is processed:</p>
                <ul>
                  <li>based on your consent;</li>
                  <li>conclusion and execution of various contracts;</li>
                  <li>to fulfill our legal obligations;</li>
                  <li>
                    for the purpose of the legitimate interests pursued by
                    Guitar Battle Club (e.g. the adoption of protection and
                    security measures for our employees, the exercise of
                    legitimate rights and interests of the Company in
                    contentious or non-contentious procedures, etc.).
                  </li>
                </ul>
                <h3 className="fw-bold mt-4">
                  5. How long we process your personal data?
                </h3>
                <p>
                  Personal data are stored for their processing for the duration
                  necessary to achieve the processing purposes mentioned in this
                  Policy or for the duration required by law (e.g. in matters of
                  archiving, accounting, etc.).
                </p>
                <h3 className="fw-bold mt-4">
                  6. To whom do we transmit personal data?
                </h3>
                <p>
                  We will not disclose or transfer to any third party any of the
                  personal data collected from or about you except:
                </p>
                <ul>
                  <li>
                    To public authorities and institutions - when there is a
                    legal obligation in this regard or for the purpose of a
                    legitimate interest (defense of the Company's rights in
                    contentious or non-contentious procedures, etc.).
                  </li>
                  <li>
                    The contractual partners or collaborators of the Company
                    that provide us with web hosting services are Hostico (SC
                    Awesome Projects SRL) and Amazon Web Services.
                  </li>
                  <li>
                    To any third party - if you have given your consent
                    expressly and punctually, for that situation and that data.
                  </li>
                </ul>
                <p>
                  If the personal data processed by the Company is transferred
                  by it directly or through its contractual
                  partners/collaborators outside the European Economic Area, we
                  assure you that these transfers take place in compliance with
                  GDPR. (in particular art. 46 of the GDPR), of the legal
                  provisions applicable in the matter, based on adequate
                  guarantees (standard data protection clauses adopted by the
                  Commission) and on the condition that there are opposable
                  rights and effective remedies for the persons concerned.
                </p>
                <h3 className="fw-bold mt-4">
                  7. What security measures and guranatees we have in place?
                </h3>
                <p>
                  Guitar Battle Club implements appropriate technical and
                  organizational measures to ensure a high level of security and
                  protection of personal data. We use security methods and
                  technologies, together with internal work policies and
                  procedures, including control and audit, to protect the
                  personal data collected in accordance with the legal
                  provisions in force relevant to the protection of personal
                  data.
                </p>
                <h3 className="fw-bold mt-4">
                  8. What are your rights. As a data subject?
                </h3>
                <p>
                  Any data subject may exercise the following rights, as
                  provided by the General Data Protection Regulation:
                </p>
                <ul>
                  <li>The right of access;</li>
                  <li>The right to rectification;</li>
                  <li>The right to erasure;</li>
                  <li>The right to restrict processing;</li>
                  <li>The right to portability;</li>
                  <li>The right to oppose the processing;</li>
                  <li>
                    The right not to be subject to a decision based exclusively
                    on automatic processing, including the creation of profiles;
                  </li>
                  <li>
                    The right to address the National Supervisory Authority for
                    the Processing of Personal Data and the courts.
                  </li>
                </ul>
                <p>
                  For any questions, concerns or the exercise of these rights,
                  you can contact us by e-mail at: info(at)guitarbattleclub.com
                  by post or courier at: 22 Plaiului Street, Nazna, Mures
                  County, Romania.
                </p>
                <p>
                  The generally applicable provisions contained in the Terms and
                  Conditions of Use apply to this document. <br />
                  You can also contact the national data protection supervisory
                  authority A.N.S.P.D.C.P. using anspdcp@dataprotection.ro email
                  address or directly at address B-dul G-ral. Gheorghe Magheru
                  28-30, Sector 1, postal code 010336, Bucharest, Romania or at
                  the phone number: +40.318.059.211.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PrivacyPolicy;
