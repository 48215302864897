import { FunctionComponent, useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom"; // Add useLocation
import BattleTabs from "./components/BattleTabs";
import BattleCard from "./components/BattleCard";
import { getAllBattles } from "../../api/battles";
import { Battle } from "../../models/Battle";
import { Helmet } from "react-helmet-async";
import { useAuth } from "../../providers/AuthProvider";
import FindOpponent from "../find_opponent/FindOpponent";

const Battles: FunctionComponent = () => {
  const navigate = useNavigate();
  const location = useLocation(); // Get the current location

  const { authState } = useAuth();
  const userId = authState.user?.id;

  const [battlesActive, setBattlesActive] = useState<Battle[]>([]);
  const [battlesFinished, setBattlesFinished] = useState<Battle[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<Error | null>(null);

  // Determine the selected tab based on the URL path
  const selectedTab = location.pathname.includes("active")
    ? "ACTIVE"
    : location.pathname.includes("finished")
    ? "FINISHED"
    : location.pathname.includes("find-opponents")
    ? "FIND_OPPONENTS"
    : "ACTIVE";

  const fetchBattles = async () => {
    try {
      setLoading(true);
      if (selectedTab === "ACTIVE") {
        const activeBattles: Battle[] = await getAllBattles("active");
        setBattlesActive(activeBattles);
      } else if (selectedTab === "FINISHED") {
        const finishedBattles: Battle[] = await getAllBattles("finished");
        setBattlesFinished(finishedBattles);
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      setError(
        error instanceof Error
          ? error
          : new Error("Failed to fetch all battles")
      );
    }
  };

  useEffect(() => {
    fetchBattles();
  }, [selectedTab]);

  // Handle tab change and update URL path
  const handleTabChange = (tab: string) => {
    if (tab === "ACTIVE") {
      navigate("/battles/active");
    } else if (tab === "FINISHED") {
      navigate("/battles/finished");
    } else if (tab === "FIND_OPPONENTS") {
      navigate("/battles/find-opponents");
    }
  };

  const onActiveBattleClick = (battle: Battle) => {
    navigate(`/battles/${battle.id}`);
  };

  return (
    <>
      <Helmet>
        <title>{"Battles"}</title>
      </Helmet>

      <div className="bg-yellow pt-5">
        <div className="container-gbc pt-5 text-black">
          <div className="row text-center pt-5">
            <div className="col-12">
              <div className="d-flex flex-column justify-content-center align-items-center gap-2">
                <h2 className="fw-normal fs-gbc-1 mb-2">Guitar battle arena</h2>
                <p className="fs-gbc-2 fw-lighter mb-0 col-md-10 col-lg-6">
                  Challenge others to guitar battles. Invite your friends to
                  vote for you to win the battle and earn rewards.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="bg-green">
        <BattleTabs onTabChange={handleTabChange} selectedTab={selectedTab} />

        <div className="container-gbc py-4 py-md-5 position-relative z-1">
          <div className="row justify-content-center align-items-center">
            {loading && (
              <div className="text-center">
                <div className="spinner-border" role="status">
                  <span className="visually-hidden">Loading...</span>
                </div>
              </div>
            )}
            {!loading &&
              selectedTab === "ACTIVE" &&
              battlesActive.length === 0 && (
                <div className="text-center">No active battles yet.</div>
              )}
            {!loading &&
              selectedTab === "FINISHED" &&
              battlesFinished.length === 0 && (
                <div className="text-center">No finished battles yet.</div>
              )}
            {selectedTab === "ACTIVE" &&
              battlesActive.map((activeBattle) => (
                <BattleCard
                  key={activeBattle.id}
                  battle={activeBattle}
                  type="ACTIVE"
                  onBattleClick={onActiveBattleClick}
                  currentUserId={userId} // Pass userId to BattleCard
                />
              ))}
            {selectedTab === "FINISHED" &&
              battlesFinished.map((finishedBattle) => (
                <BattleCard
                  key={finishedBattle.id}
                  battle={finishedBattle}
                  type="FINISHED"
                  onBattleClick={onActiveBattleClick}
                  currentUserId={userId} // Pass userId to BattleCard
                />
              ))}
            {selectedTab === "FIND_OPPONENTS" && <FindOpponent />}
          </div>
        </div>
      </div>
    </>
  );
};

export default Battles;
