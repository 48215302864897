import api, { apiBaseUrl } from "./config/apiConfig";
import { Level } from "../models/Level";

export const getLevels = async (accessToken: string): Promise<Level[]> => {
  try {
    const response = await api.get(`${apiBaseUrl}/levels`, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });

    if (response.status === 200) {
      return response.data;
    } else {
      throw new Error("Get levels failed");
    }
  } catch (error) {
    console.error("Get levels failed:", error);
    return [];
  }
};
