import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { LearnSongsCardType } from "../views/songs/LearnSongsCard";
import { getSongsByLevel } from "../api/songs";
import { Song } from "../models/Song";

// Async thunk to fetch data from the API
export const fetchLearnSongsFromAPI = createAsyncThunk<
  LearnSongsCardType[],
  { accessToken: string; level: string | null , subLevel: string , offset?: number, limit?: number },
  {
    rejectValue: string; // Define the type for the rejectValue
  }
>(
  "songs/fetchLearnSongs",
  async ({ accessToken, level, subLevel, offset, limit }, { rejectWithValue, dispatch }) => {
    try {
      if (level == null) {
        return [];
      }
      const songs: Song[] = await getSongsByLevel(accessToken, level, subLevel, offset, limit);

      if (songs) {
        return songs;
      } else {
        throw new Error("Empty response or invalid data");
      }
    } catch (error) {
      // Handle API request or response errors here
      return rejectWithValue("Failed to fetch learn songs: " + error);
    }
  }
);

const postsSlice = createSlice({
  name: "songs",
  initialState: [] as LearnSongsCardType[],
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchLearnSongsFromAPI.fulfilled, (state, action) => {
      return action.payload;
    });
    builder.addCase(fetchLearnSongsFromAPI.rejected, (state, action) => {
      return state; // Just return the current state as you might not want to change it on rejection
    });
  },
});

export default postsSlice.reducer;

// Actions
export const songsActions = {
  ...postsSlice.actions,
  fetchLearnSongsFromAPI,
};
