import { FunctionComponent } from "react";
import { User } from "../../../models/User";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHeart } from "@fortawesome/free-solid-svg-icons";

export enum PlayerStatus {
  WINNER = "WINNER",
  TIE = "TIE",
  LOSER = "LOSER",
}

export type PlayerSongCardProps = {
  user: User;
  likes: number;
  playerStatus?: PlayerStatus;
  video?: {
    song: {
      artistName: string;
      songTitle: string;
    };
  };
  tabType: string;
};

const PlayerSongCard: FunctionComponent<PlayerSongCardProps> = ({
  user,
  likes,
  playerStatus,
  video,
  tabType,
}) => {
  return (
    <>
      <img
        width="44"
        height="44"
        className="rounded-circle"
        crossOrigin="anonymous"
        alt={user?.firstName || ""}
        src={user?.pictureUrl || "/profile_placeholder.png"}
      />

      <div className="d-flex flex-column gap-1 w-100">
        <div className="d-flex justify-content-between">
          <h4 className="fs-4 fw-bold m-0">
            {user?.firstName} {user?.lastName}
          </h4>
          <small className="d-flex justify-content-between align-items-center">
            {likes >= 0 && (
              <>
                {tabType === "FINISHED" &&
                (playerStatus == "WINNER" || playerStatus == "TIE") ? (
                  <span className="badge bg-black">
                    {playerStatus == "TIE" ? "Tie" : "Winner"}{" "}
                    <FontAwesomeIcon icon={faHeart} className="me-2" /> {likes}
                  </span>
                ) : (
                  <span className="badge bg-black">
                    <FontAwesomeIcon icon={faHeart} className="me-2" /> {likes}
                  </span>
                )}
              </>
            )}
          </small>
        </div>
        <div className="d-flex gap-2 align-items-center flex-wrap">
          <p className="m-0 fw-bold">{video?.song?.artistName || ""}</p>
          <p className="m-0">-</p>
          <p className="m-0 fw-normal">{video?.song?.songTitle || ""}</p>
        </div>
      </div>
    </>
  );
};

export default PlayerSongCard;
