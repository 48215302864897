import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import api from "../../api/config/apiConfig";

const UnsubscribeEmailContainer: React.FC = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [unsubscribeMessage, setUnsubscribeMessage] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [isError, setIsError] = useState(false);
  const token = window.location.pathname.split("/").pop();

  useEffect(() => {
    // Check if token is provided, then make a request to unsubscribe
    if (token) {
      api
        .post(`/users/unsubscribe`, { token }) // Assuming your API uses a POST method to unsubscribe
        .then((response) => {
          if (response.status === 200) {
            setUnsubscribeMessage("You have been unsubscribed successfully.");
          } else {
            setUnsubscribeMessage(
              "Something went wrong, you could not be unsubscribed."
            );
          }

          setIsLoading(false);
        })
        .catch((error) => {
          const errorMessage =
            error.response?.data.message || "An error occurred.";
          setUnsubscribeMessage(errorMessage);
          setIsLoading(false);
          setIsError(true);
        });
    } else {
      setUnsubscribeMessage("No unsubscribe token provided.");
      setIsLoading(false);
      setIsError(true);
    }
  }, [token]);

  const handleClose = () => {
    navigate("/home");
  };

  return (
    <div className="login-bgs">
      <div className="container-gbc py-5">
        <div className="row justify-content-center">
          <div className="col col-md-10 col-lg-6">
            <div className="rounded-5 p-5 p-md-5 my-5 card-songs">
              <div>
                {isLoading ? (
                  <p>Processing your request...</p>
                ) : (
                  <div>
                    <p>{unsubscribeMessage}</p>
                    {!isError && (
                      <button className="bttn" onClick={handleClose}>
                        Close
                      </button>
                    )}
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UnsubscribeEmailContainer;
