import React from "react";
import { OpponentVideo } from "../../models/OpponentVideo";
import { getUserName } from "../../utility/TextFormatter";

type OpponentCardProps = {
  opponentVideo: OpponentVideo;
  onChallengeToBattleClick: (
    opponentVideoId: number,
    opponentName: string
  ) => void;
};

const OpponentCard: React.FC<OpponentCardProps> = ({
  opponentVideo,
  onChallengeToBattleClick,
}) => {
  const userName = getUserName(opponentVideo.user);

  return (
    <div className="col-12 col-md-6 col-lg-4 mb-4">
      <div className="card-songs card-songs-listing d-flex flex-column h-100 rounded-5">
        <div className="d-flex flex-column">
          <div className="d-flex flex-column">
            <div className="d-flex flex-row border-bottom p-3 p-md-3 gap-4 flex-wrap align-items-center">
              <img
                width="44"
                height="44"
                className="rounded-circle"
                alt={opponentVideo.user?.firstName || "User profile picture"}
                crossOrigin="anonymous"
                src={
                  opponentVideo.user?.pictureUrl || "/profile_placeholder.png"
                }
              />

              <div className="d-flex flex-column gap-1">
                <div className="d-flex justify-content-between">
                  <h4 className="fs-4 fw-bold m-0">{userName}</h4>
                </div>
                <div className="d-flex gap-2 align-items-center flex-wrap">
                  <p className="m-0 fw-bold">
                    {opponentVideo.song?.artistName || " "}
                  </p>
                  <p className="m-0">-</p>
                  <p className="m-0 fw-normal">
                    {opponentVideo.song?.songTitle || " "}
                  </p>
                  {/* <p className="m-0">-</p>
                  <p className="fw-normal m-0">
                    {opponentVideo.percentage || 0}%
                  </p> */}
                </div>
              </div>
            </div>
          </div>

          <div className="d-flex p-3 p-md-3">
            <button
              className="bttn ms-auto"
              onClick={() =>
                onChallengeToBattleClick(
                  opponentVideo.id,
                  getUserName(opponentVideo.user)
                )
              }
            >
              Challenge to battle
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OpponentCard;
