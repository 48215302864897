import { FunctionComponent } from "react";
import { Helmet } from "react-helmet-async";

const HowToPlay: FunctionComponent = () => {

  return (
    <>
      {/* Helmet component to dynamically set the page title */}
      <Helmet>
        <title>{"Make money"}</title>
      </Helmet>

      <div className="bg-yellow py-5 border-bottom">
        <div className="container-gbc pt-5 text-black">
          <div className="row text-center pt-5">
            <div className="col-12">
              <div className="d-flex flex-column justify-content-center align-items-center gap-2">
                <h2 className="fw-normal fs-gbc-1 mb-2">Make Money</h2>
                <p className="fs-gbc-2 fw-lighter mb-0 col-md-10 col-lg-6">
                  You will be able to trade your points for cash with Guitar
                  Battle Club.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="bg-green">
        <div className="container-gbc py-4 py-md-5">
          <div className="row justify-content-center">
            <div className="col-12 col-md-6 col-lg-3 mb-4">
              <div className="card-songs d-flex flex-column p-3 p-md-4 h-100 gap-3 bg-white">
                <h3 className="fs-4 m-0 fw-bold">Become a teacher</h3>
                <p className="mb-0">
                  If you're an original guitarist ready to teach lessons, Guitar
                  Battle Club gives you access to guitarists from all over the
                  world who can learn from your lessons. You'll get paid each
                  time someone learns one of your songs!
                </p>
              </div>
            </div>
            <div className="col-12 col-md-6 col-lg-3 mb-4 text-center">
              <div className="card-songs d-flex flex-column p-3 p-md-4 h-100 gap-3 justify-content-between">
                <h3 className="fs-4 m-0 fw-bold">
                  If you'd like to become a teacher, apply here.
                </h3>
                <a
                  href="https://docs.google.com/forms/d/e/1FAIpQLSeSdDC0iMDRMU9Qrxo1Th-qfKtdsWbDHcdf4X8KhH5gGW5WSQ/viewform"
                  target="_blank"
                  className="bttn"
                >
                  Apply here
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default HowToPlay;
