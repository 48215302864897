import { FunctionComponent, useMemo, type CSSProperties, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../providers/AuthProvider";
import { googleLogout } from '@react-oauth/google';

const Footer: FunctionComponent = () => {
  const { authState, logout } = useAuth();
  const navigate = useNavigate();

  /** Action props */
  const onHomeClick = useCallback(() => {
    navigate("/home");
  }, [navigate]);

  // const onHowToPlayClick = useCallback(() => {
  //   navigate("/how-to-play");
  // }, [navigate]);

  const onLearnSongsTextClick = () => {
    navigate("/learn-song-list");
  };

  const onBattlesTextClick = useCallback(() => {
    navigate("/battles");
  }, [navigate]);

  const onLogInTextClick = useCallback(() => {
    navigate("/sign-in");
  }, [navigate]);

  const onLogOutClick = useCallback(() => {
    googleLogout();
    logout();
    // navigate("/how-to-play");
  }, []);

  const onMyDashboardClick = useCallback(() => {
    navigate("/my-dashboard");
  }, []);

  const onMyVideosClick = () => {
    navigate("/my-videos");
  };

  const onMyLessonsClick = useCallback(() => {
    navigate("/my-lessons");
  }, []);

  const onMyBattlesClick = useCallback(() => {
    navigate("/my-battles");
  }, []);

  const onSignUpTextClick = useCallback(() => {
    navigate("/sign-up");
  }, [navigate]);

  const onPrivacyPolicyClick = useCallback(() => {
    navigate("/privacy-policy");
  }, [navigate]);

  const onTermsAndConditionsClick = useCallback(() => {
    navigate("/terms-and-conditions");
  }, [navigate]);

  const onCookiesClick = useCallback(() => {
    console.log("cookies");
    navigate("/cookies");
  }, [navigate]);

  const onMakeMoneyTextClick = useCallback(() => {
    navigate("/make-money");
  }, [navigate]);

  const onAccountTextClick = useCallback(() => {
    // Please sync "learn songs --- single" to the project
  }, []);

  return (
    <footer className="">
      <div className="container-gbc pt-5">
        <div className="row row-cols-2 row-cols-md-5 py-5 mb-5 justify-content-between">
          <div className="col-12 col-lg-2 mb-5">
            <button onClick={onHomeClick} className="d-flex align-items-center mb-2 mb-lg-0 border-0 bg-transparent">
              <img src="/logo-gbc-new.svg" alt="" />
            </button>
          </div>

          <div className="col mb-4">
            <h5 className="mb-4 fw-bold">GBC</h5>
            <ul className="nav flex-column">
              {/* <li><a href="" onClick={onHowToPlayClick} className="nav-link ps-0 link-body-emphasis">How to play</a></li> */}
              <li><a href="" onClick={onLearnSongsTextClick} className="nav-link ps-0 link-body-emphasis">Learn Songs</a></li>
              <li><a href="" onClick={onBattlesTextClick} className="nav-link ps-0 link-body-emphasis">Battle Arena</a></li>
              <li><a href="" onClick={onMakeMoneyTextClick} className="nav-link ps-0 link-body-emphasis">Make Money</a></li>
            </ul>
          </div>

          <div className="col mb-4">
            <h5 className="mb-4 fw-bold">User</h5>
            {authState.isAuthenticated ? (
              <ul className="nav flex-column">
                {/* <li><button onClick={onMyDashboardClick} className="nav-link ps-0 link-body-emphasis">My dashboard</button></li> */}
                <li><button onClick={onMyVideosClick} className="nav-link ps-0 link-body-emphasis">My videos</button></li>
                <li><button onClick={onMyLessonsClick} className="nav-link ps-0 link-body-emphasis">My lessons</button></li>
                <li><button onClick={onMyBattlesClick} className="nav-link ps-0 link-body-emphasis">My battles</button></li>
                <li className="nav-item mb-2"><button onClick={onLogOutClick} className="nav-link ps-0 link-body-emphasis">Log out</button></li>
              </ul>
            ) : (
              <ul className="nav flex-column">
                <li><button onClick={onLogInTextClick} className="nav-link ps-0 link-body-emphasis">Log in</button></li>
                <li><button onClick={onSignUpTextClick} className="nav-link ps-0 link-body-emphasis">Sign up</button></li>
              </ul>
            )}
          </div>

          <div className="col mb-4">
            <h5 className="mb-4 fw-bold">Legal</h5>
            <ul className="nav flex-column">
              <li className="nav-item mb-2"><button onClick={onPrivacyPolicyClick} className="nav-link ps-0 link-body-emphasis">Privacy policy</button></li>
              <li className="nav-item mb-2"><button onClick={onTermsAndConditionsClick} className="nav-link ps-0 link-body-emphasis">Terms &amp; conditions</button></li>
              <li className="nav-item mb-2"><button onClick={onCookiesClick} className="nav-link ps-0 link-body-emphasis">Cookies</button></li>
            </ul>
          </div>

          <div className="col mb-4">
            <h5 className="mb-4 fw-bold">We are social</h5>
            <ul className="nav flex-column">
              <li className="nav-item mb-2"><a href="https://www.facebook.com/GuitarBattleClub/" target="_blank" className="nav-link ps-0 link-body-emphasis">Facebook</a></li>
              <li className="nav-item mb-2"><a href="https://www.instagram.com/guitarbattleclub/" target="_blank" className="nav-link ps-0 link-body-emphasis">Instagram</a></li>
              {/* <li className="nav-item mb-2"><a href="#" target="_blank" className="nav-link ps-0 link-body-emphasis">Tiktok</a></li>
              <li className="nav-item mb-2"><a href="#" target="_blank" className="nav-link ps-0 link-body-emphasis">Linkedin</a></li>
              <li className="nav-item mb-2"><a href="#" target="_blank" className="nav-link ps-0 link-body-emphasis">Youtube</a></li> */}
            </ul>
          </div>
        </div>
        <div>
          <p className="text-body-secondary mb-0 pb-4">&copy; Guitar Battle Club 2024.</p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
