import { openDB, DBSchema } from "idb";

const DB_NAME = "videoCacheDB";
const STORE_NAME = "videos";

interface VideoDB extends DBSchema {
  videos: {
    key: string;
    value: Blob;
  };
}

const initDB = async () => {
  return openDB<VideoDB>(DB_NAME, 1, {
    upgrade(db) {
      if (!db.objectStoreNames.contains(STORE_NAME)) {
        db.createObjectStore(STORE_NAME);
      }
    },
  });
};

export const setVideoInDB = async (key: string, blob: Blob): Promise<void> => {
  try {
    const db = await initDB();
    const tx = db.transaction(STORE_NAME, "readwrite");
    await tx.store.put(blob, key);
    await tx.done;
  } catch (error) {
    console.error("Error setting video in DB:", error);
  }
};

export const getVideoFromDB = async (
  key: string
): Promise<Blob | undefined> => {
  try {
    const db = await initDB();
    const tx = db.transaction(STORE_NAME, "readonly");
    const blob = await tx.store.get(key);
    await tx.done;
    return blob;
  } catch (error) {
    console.error("Error getting video from DB:", error);
    return undefined;
  }
};

export const clearOldVideos = async (maxItems: number): Promise<void> => {
  try {
    const db = await initDB();
    const tx = db.transaction(STORE_NAME, "readwrite");
    const keys = await tx.store.getAllKeys();

    if (keys.length > maxItems) {
      const keysToDelete = keys.slice(0, keys.length - maxItems);
      await Promise.all(keysToDelete.map((key) => tx.store.delete(key)));
    }

    await tx.done;
  } catch (error) {
    console.error("Error clearing old videos:", error);
  }
};
