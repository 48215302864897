import React, { FC, useCallback } from "react";
import { useNavigate } from "react-router-dom";

interface SignUpStep2Props {}

const SignUpStep2: FC<SignUpStep2Props> = () => {
  const navigate = useNavigate();

  const onLogInTextClick = useCallback(() => {
    navigate("/sign-in");
  }, [navigate]);

  return (
    <div className="rounded-5 p-5 p-md-5 card-songs my-5">
      <div className="sign-in">
        <div className="step-2-of-2-parent">
          <div className="step-2-of">Step 2 of 2</div>
          <div className="create-an-account">Create an account</div>
          <div className="already-have-an-container">
            Already have an account?
            <span className="sign-in-text" onClick={onLogInTextClick}>
              Sign in
            </span>
          </div>
        </div>
        <div className="frame-wrapper">
          <div className="frame-group">
            <div className="frame-container">
              <div className="first-name-wrapper">
                <div className="step-2-of">First name</div>
              </div>
              <div className="first-name-wrapper">
                <div className="step-2-of">Last name</div>
              </div>
            </div>
            <div className="date-of-birth-wrapper">
              <div className="step-2-of">Date of birth</div>
            </div>
            <div className="frame-container">
              <div className="month-parent">
                <div className="step-2-of">Month</div>
              </div>
              <div className="first-name-wrapper">
                <div className="step-2-of">Year</div>
              </div>
            </div>
            <div className="country-region-parent">
              <div className="step-2-of">Country / Region</div>
            </div>
            <div className="vector-parent">
              <img className="vector-icon" alt="" src="Vector.svg" />
              <div className="terms-and-conditions">
                Terms and conditions acceptance text here.
              </div>
            </div>
            <div className="button">
              <div className="step-2-of">Create account</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default SignUpStep2;
