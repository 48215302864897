export class Level {
  id: string;
  name: string;
  subLevels: string[];

  constructor(id: string, name: string, subLevels: string[] = []) {
    this.id = id;
    this.name = name;
    this.subLevels = subLevels;
  }
}
