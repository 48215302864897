import { createSlice } from '@reduxjs/toolkit'

const initialState : any[]=
    [{
        id: 1,
        artist: "Iron Maiden",
        song: "Fear of the dark",
        player1avatar : '/avatar1.png',
        player1name: 'John Doe 1',
        player1level: '1',
    },
    {
        id: 2,
        artist: "Iron Maiden",
        song: "Fear of the dark",
        player1avatar : '/avatar1.png',
        player1name: 'John Doe 1',
        player1level: '1',
    },
    {
        id: 3,
        artist: "Iron Maiden",
        song: "Fear of the dark",
        player1avatar : '/avatar1.png',
        player1name: 'John Doe 1',
        player1level: '1',
    }
    ]

const challengesSlice = createSlice({
    name: 'challenges',
    initialState,
    reducers: {}
})

export default challengesSlice.reducer