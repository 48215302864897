import React from "react";
import { Modal, Button } from "react-bootstrap";

interface ErrorPopupProps {
  onClose: () => void;
  title: string;
  message: string;
}

const ErrorPopup: React.FC<ErrorPopupProps> = ({ onClose, title, message }) => {
  return (
    <Modal show={true} onHide={onClose}>
      <Modal.Header closeButton>
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>{message}</p>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={onClose}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ErrorPopup;
