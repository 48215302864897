import api from "./config/apiConfig";

export const forgotPassword = async (email: string) => {
  try {
    const response = await api.post(`/forgot-password`, {
      email,
    });
    if (response.status === 200) {
      return response.data;
    } else {
      throw new Error("Forgot password failed");
    }
  } catch (error) {
    console.error("Forgot password failed:", error);
    throw error;
  }
};