import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import api from "../../api/config/apiConfig";

const VerifyEmailContainer: React.FC = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [verificationMessage, setVerificationMessage] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [isError, setIsError] = useState(false);
  const token = window.location.pathname.split("/").pop();

  useEffect(() => {
    // Check if token is provided, then make a request to verify the email
    if (token) {
      api
        .get(`/signup/verify-email?token=${token}`)
        .then((response) => {
          if (response.status === 200) {
            setVerificationMessage("Your email was verified successfully.");
          } else {
            setVerificationMessage(
              "Something went wrong, your email was not verified."
            );
          }

          setIsLoading(false);
        })
        .catch((error) => {
          const errorMessage =
            error.response?.data.message || "An error occurred.";
          setVerificationMessage(errorMessage);
          setIsLoading(false);
          setIsError(true);
        });
    } else {
      setVerificationMessage("No verification token provided.");
      setIsLoading(false);
      setIsError(true);
    }
  }, [token]);

  const handleClose = () => {
    navigate("/home");
  };

  return (
    <div className="login-bgs">
      <div className="container-gbc py-5">
        <div className="row justify-content-center">
          <div className="col col-md-10 col-lg-6">
            <div className="rounded-5 p-5 p-md-5 my-5 card-songs">
              <div>
                {isLoading ? (
                  <p>Verifying your email...</p>
                ) : (
                  <div>
                    <p>{verificationMessage}</p>
                    {!isError && (
                      <button className="bttn" onClick={handleClose}>
                        Close
                      </button>
                    )}
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default VerifyEmailContainer;
